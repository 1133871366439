@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&family=Oxygen+Mono&display=swap");

@import url("./highlightjs/styles/ir-black.css");
@import "aos/dist/aos.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn-primary {
		@apply focus:outline-none px-3 py-1 text-xs sm:text-sm cursor-pointer transform transition-transform hover:scale-105 bg-gradient-to-br from-darkBlue to-red-800 tracking-widest rounded-md shadow-md text-gray-50 font-thin;
	}
	.btn-secondary {
		@apply focus:outline-none px-3 py-1 text-xs sm:text-sm text-gray-50 hover:text-red-500 cursor-pointer transition bg-transparent tracking-widest rounded-md font-thin;
	}
	.btn-tertiary {
		@apply focus:outline-none px-3 py-1 border sm:text-sm hover:bg-darkBlue cursor-pointer text-xs transition bg-transparent tracking-widest rounded-md shadow-md font-thin border-darkBlue text-darkBlue hover:text-gray-50;
	}
	.input-no-line {
		@apply focus:ring-0 focus:outline-none;
	}
	textarea {
		@apply focus:ring-0 focus:outline-none;
	}
}
@layer utilities {
	@variants responsive {
		.cs-border-outset {
			border-style: outsetf;
			border-color: rgba(220, 38, 38, 0.4);
			border-width: 5px;
		}
	}
}
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #181818;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(53, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(100, 0, 0);
}